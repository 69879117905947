/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPostMeme = /* GraphQL */ `
  mutation CreatePostMeme(
    $input: CreatePostMemeInput!
    $condition: ModelPostMemeConditionInput
  ) {
    createPostMeme(input: $input, condition: $condition) {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
export const updatePostMeme = /* GraphQL */ `
  mutation UpdatePostMeme(
    $input: UpdatePostMemeInput!
    $condition: ModelPostMemeConditionInput
  ) {
    updatePostMeme(input: $input, condition: $condition) {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
export const deletePostMeme = /* GraphQL */ `
  mutation DeletePostMeme(
    $input: DeletePostMemeInput!
    $condition: ModelPostMemeConditionInput
  ) {
    deletePostMeme(input: $input, condition: $condition) {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
