/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPostMeme = /* GraphQL */ `
  query GetPostMeme($id: ID!) {
    getPostMeme(id: $id) {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
export const listPostMemes = /* GraphQL */ `
  query ListPostMemes(
    $filter: ModelPostMemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostMemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        affiliate
        file
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
