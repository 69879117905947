import logo from './logo.svg';
import './App.css';

import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import history from './history';


import Header from "./components/Header"
import Meme from "./components/Meme"
import Mansonry from "./components/Mansonry"



import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  
  return (
    <div className="App">

        {/* <HttpsRedirect> */}
            <BrowserRouter history={history} basename="/">
              <Suspense fallback={<div>Cargando...</div>}>
                <Routes>
                  <Route path="/" exact element={<Mansonry/>}/>
                </Routes>
              </Suspense>
            </BrowserRouter>
        {/* </HttpsRedirect> */}
    </div>
  );
}

export default App;
