/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://gbzmcnkb2vhlrmjoa3huq5tt4e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dw7bq6whofha3fqhwjm7u4zuru",
    "aws_cognito_identity_pool_id": "us-east-1:349a70a2-356c-426a-8799-aa92907014aa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1XD7qiDUL",
    "aws_user_pools_web_client_id": "63n040qmr7m1qbbgpshta3ou53",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "s3memecreator101256-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
