import React, { useEffect, useLayoutEffect, useState } from 'react';
import './styles/mansonry.css';
import logo from './images/logo.png';
import Macy from 'macy';
import $ from 'jquery';
import { clamp, gsap } from "gsap";

import Cookies from "js-cookie";

import illustration from './images/1.png'

import img_1 from './images/img-1.png'
import img_2 from './images/img-2.png'
import img_3 from './images/img-3.png'
import img_4 from './images/img-4.png'

import memesData from "../memesData.js"

import html2canvas from 'html2canvas';

import { API , Storage, Amplify, Auth } from "aws-amplify";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { onCreatePostMeme } from '../graphql/subscriptions';

import awsconfig from '../aws-exports';

Amplify.configure(awsconfig)

const Mansonry = () => {
  const [firstEffectCompleted, setFirstEffectCompleted] = useState(false);
  const [secondEffectCompleted, setSecondEffectCompleted] = useState(false);
  const [memeArray, setMemeArray] = useState([]);
  const [indexArray, setIndexArray] = useState(18);

  const [displayNew, setDisplayNew] = useState(false)
  const [displayMenu, setDisplayMenu] = useState(false)

  const [boarding, setBoarding] = useState(false)
  

  const [meme, setMeme] = React.useState({
    topText: "",
    bottomText: "",
    randomImage: "" 
  })


  const [memePlaceholder, setMemePlaceholder] = useState({
    top: "Type here",
    bottom: "Type here"
  })


  const [allMemes, setAllMemes] = React.useState([])

  const [myMeme, setMyMeme] = React.useState('')

  const [upload, setUpload] = useState(false)

  const [postDone, setPostDone] = useState(false)

  const [newPostIncoming, setNewPostIncoming] = useState(false)

  const [name, setName] = useState('')
  const [affiliate, setAffiliate] = useState('');
  const [readyToUpload, setReadyToUpload] = useState(false)

  const [userFile, setUserFile] = useState('')
  const [fileUrl, setFileUrl] = useState('');

  const [indexMemes, setIndexMemes] = useState(0)

  const [disabledChangeMeme, setDisabledChangeMeme] = useState(false)

async function getMemeImage() {

    console.log('allMemes', allMemes, indexMemes)

    setDisabledChangeMeme(true)

    gsap.fromTo(`#meme-generator` , {opacity: 1}, { duration: 0.3, opacity: 0, delay: 0, ease: 'Circ.out',onComplete: async () => {
      await setMeme(prevMeme => ({
        ...prevMeme,
        randomImage: `https://s3memecreator101256-dev.s3.amazonaws.com/public/${allMemes[indexMemes]}`
      }))

      setTimeout(() => {
        setDisabledChangeMeme(false)
      }, 1500);

      if(indexMemes === allMemes.length -1 ){
        setIndexMemes(0)
      } else {
        setIndexMemes(indexMemes + 1)
      }

      gsap.fromTo(`#meme-generator` , {opacity: 0}, { duration: 0.7, opacity: 1, delay: 1, ease: 'Circ.out',onComplete: () => {}})
        
      }})
}

function handleChange(event) {
  console.log('event', event.target)
    const {name, value} = event.target
    setMeme(prevMeme => ({
        ...prevMeme,
        [name]: value
    }))
}


useEffect(() => {
    console.log('indexMemes', indexMemes)
}, [indexMemes])



async function PrintDiv(){

      await setMemePlaceholder({ top: "", bottom: "" })
      await  setTimeout(() => {
        setUpload(true)
      }, 1000); 
    
}

useEffect(() => {
  if(upload){
    const divGenerator = document.getElementById('meme-generator');

    html2canvas(divGenerator, {useCORS: true, logging: true, scale: 2 }).then(canvas => {

        var myImage = canvas.toDataURL('image/*');
        setMyMeme(canvas.toDataURL('image/*'))

        const imageBuffer = Buffer.from(myImage.split(',')[1], 'base64');
        
        setUserFile(imageBuffer)
    })
    .catch(err => {
      console.log('Error al generar la imagen:', err);
    });
  }
}, [upload])


useEffect(() => {

    if(userFile){
        window.moveToNextSlide();
        animateImgs(1);
        testUpload();
    }

}, [userFile])


async function testUpload(){

    const fileName = `${name}_${Date.now()}.png`;

    console.log(`fileName`, fileName)

    console.log('userFile', userFile)

    const result = await Storage.put(fileName, userFile, {
        contentType: 'image/png'
    })


    await Storage.list('')
    .then((data) => {
        data.results.map( async (result) => {
            if(result.key === fileName){
                console.log(`fileName`, fileName)
                console.log(`key`, result.key)

                await setFileUrl(result.key)
                await setReadyToUpload(true)
            }
        })
    })
    .catch((err) => console.log(err));

    console.log(`result`, result)
}


// Use Effect to send data
useEffect(() => {

    async function fetchData(){

        const input = {
            name: name,
            affiliate: affiliate,
            file: `https://s3memecreator101256-dev.s3.amazonaws.com/public/${fileUrl}`,
        };

        console.log('dataUser', input)

        const newPost = await API.graphql({ query: mutations.createPostMeme, variables: { input: input } });

        console.log('newPost', newPost)

        if(newPost){
          setPostDone(true)
        }
    }

    if(readyToUpload === true ){
        console.log('Fetchind data post', readyToUpload)

        setReadyToUpload(false)
        fetchData()
    }

  }, [readyToUpload])


  useEffect(() => {
    if(postDone){

      animateImgs(1);

      window.moveToNextSlide()
      setPostDone(false)

      let myNewPost = { file: `https://s3memecreator101256-dev.s3.amazonaws.com/public/${fileUrl}` , name: name, affiliate: affiliate }

      setMemeArray(prev => [ myNewPost, ...prev ])

    }
  }, [postDone])


  useEffect(() => {
    async function FetchData() {

      const Posts = await API.graphql({ query: queries.listPostMemes });
      const PostsItems = Posts.data.listPostMemes.items;

      PostsItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
      console.log('PostsItems', PostsItems)
      setMemeArray(PostsItems);
      setFirstEffectCompleted(true);
      $(".loader").hide();

    }
    FetchData();
  }, [newPostIncoming]);


  const handleSubscription = async () => {
    const subscription = API.graphql({ query: onCreatePostMeme }).subscribe({
      next: async (data) => {
        console.log('subscription data:', data.value.data.onCreatePostMeme);
        const object = data.value.data.onCreatePostMeme;
        setNewPostIncoming(true)

      },
    });
    
    // Detener la suscripción cuando sea necesario
    // subscription.unsubscribe();
  };

  
  useEffect(() => {
    // handleSubscription();
  }, []);



  // Fetch imgs stored in S3
  useEffect(() => {

    async function FetchData() {

      let array1 = []
      let array2 = []


      await Storage.list('meme/')
      .then(async (data) => {

        const results = data.results.slice(1);

        console.log('results', results)

        for (let i = results.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [results[i], results[j]] = [results[j], results[i]];
        }

        results.map(result => array1.push(result.key))

        // await data.results.map( async (result, index) => {
        //     if( index === 0 ){ return null } else {
        //       setAllMemes(prev => [...prev, result.key])
        //     } 
        // })
      })
      .catch((err) => console.log(err));

      await Storage.list('easy/')
      .then(async (data) => {

        const results2 = data.results.slice(1);

        console.log('results2', results2)

        for (let i = results2.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [results2[i], results2[j]] = [results2[j], results2[i]];
        }

        results2.map(result => array2.push(result.key))

        // await data.results.map( async (result, index) => {
        //     if( index === 0 ){ return null } else {
        //       setAllMemes(prev => [...prev, result.key])
        //     } 
        // })
      })
      .catch((err) => console.log(err));

      
      array2.concat(array1); 
      
      // console.log('array1, array2', array2.concat(array1))
      setAllMemes(array2.concat(array1));

    }


    if(firstEffectCompleted){
      FetchData();

      if(!Cookies.get("token")){
        showOnBoarding()
        Cookies.set('token', '123', { expires: 7 });
      }
    }
  }, [firstEffectCompleted]);

  useEffect(() => {
    console.log('allmemes', allMemes)
    if(allMemes.length > 0){
      getMemeImage()
    }
  }, [allMemes])


  useEffect(() => {
    console.log('memeArray', memeArray, indexArray)
  }, [indexArray])
  

  useEffect(() => {
    function init() {
      var MyMacy = Macy({
        container: '#photos',
        trueOrder: true,
        waitForImages: true,
        margin: 10,
        debug: true,
        columns: 4,
        useImageLoader: true,
        useOwnImageLoader: false,
        breakAt: {
          1920: 6,
          1480: 4,
          940: 3,
          520: 2,
        },
      });
      MyMacy.runOnImageLoad(function () {
        MyMacy.recalculate(true, true);
      });
      setInterval(() => {
        MyMacy.recalculate(true, true);
      }, 500);
      $(window).scroll(function () {
        MyMacy.runOnImageLoad(function () {
          MyMacy.recalculate(true, true);
        });
      });

      setSecondEffectCompleted(true)
    }
    if (firstEffectCompleted) {
      init();
    }
  }, [firstEffectCompleted]);



  async function showOnBoarding(){
      animateImgs()

      gsap.to("#welcome-overlay", {
        duration: 0.5,
        opacity: 1,
        zIndex: 10000,
        ease: "power1.inOut",
        onComplete: () => {
          gsap.to("#welcome-overlay .stag", {
            delay: 1,
            duration: 0.5,
            stagger: 0.5,
            opacity: 1,
            ease: "power1.inOut",
            onComplete: () => {

              gsap.to("#welcome-overlay .stag2", {
                delay: 1,
                duration: 0.5,
                stagger: 0.5,
                opacity: 1,
                ease: "power1.inOut",
                onComplete: () => {

                  gsap.to("#welcome-overlay .stag3", {
                    delay: 1,
                    duration: 0.5,
                    stagger: 1,
                    opacity: 1,
                    ease: "power1.inOut",
                    onComplete: () => {

                      gsap.to("#welcome-overlay #closeMenu", {
                        delay: 1,
                        duration: 0.5,
                        opacity: 1,
                        y: 0,
                        ease: "power1.inOut",
                      })

                      gsap.fromTo("#welcome-overlay .explore__gallery", { y: 10 }, {
                        delay: 0,
                        duration: 0.5,
                        opacity: 1,
                        y: 0,
                        ease: "power1.inOut",
                      })
                    }

                  })

                }
              })

            }
          })
        }
      });

      $('body').addClass('stop-scrolling')
  }

  async function closeOnBoarding() {
      gsap.to("#welcome-overlay", {
        duration: 0.5,
        opacity: 0,
        display: 'none',
        zIndex: 10000,
        ease: "power1.inOut",
      });
  }


  async function createMeme() {

    gsap.to("#creatingMeme", {
      duration: 0.5,
      opacity: 1,
      zIndex: 10000,
      ease: "power1.inOut",
    });

    setDisplayMenu(true)

    $('body').addClass('stop-scrolling')
  }

  async function closeMenu() {

    setDisplayMenu(false)

    gsap.to("#creatingMeme", {
      duration: 0.5,
      opacity: 0,
      zIndex: -1,
      ease: "power1.inOut",
      onComplete: () => {
        window.resetCarousel()
        setName('')
        setAffiliate('')
        setBoarding(true)
        
        setMeme({ 
          topText: "",
          bottomText: ""
        })

        if(allMemes.length > 0){
          getMemeImage()
        }

        setMemePlaceholder({ top: "Type here", bottom: "Type here" })
        setUpload(false)

        gsap.to(".slide__image", { scale: 0.8, opacity: 0 })

      }
    });

    $('body').removeClass('stop-scrolling')
  }


  useEffect(() => {
    if(boarding){
      window.resetCarousel()
    }
  }, [boarding])
  

  function resumeScroll() {
    $(window).on("scroll", async function () {

      if ($(document).height() <= $(window).scrollTop() + $(window).height()) {
          $(window).off('scroll');

          $(".loader").show() 
          $('body').addClass('stop-scrolling')

          setDisplayNew(true)
      }
    });
  }
  

  async function display(){
      setDisplayNew(false)

      gsap.fromTo(".pushed",  { y: 20 },  {
        duration: 0.5,
        opacity: 1,
        ease: "power1.inOut",
        stagger: 0.2,
        y: 0,
        onComplete: () => {
          document.querySelectorAll('.ParentDiv').forEach((element) => {
            $(element).removeClass('pushed')
            $(".loader").hide()
            $('body').removeClass('stop-scrolling')

          });
        }
      });
  }


  useEffect(() => {
    async function fetchNewData() {
      if (indexArray + 4 <= memeArray.length) {
        setIndexArray((prev) => prev + 4); // Actualizar el estado indexArray
        console.log('diference', indexArray + 4 <= memeArray.length);
        resumeScroll();
        setTimeout(() => {
          display(); // Ejecutar la función display después de 100ms (puedes ajustar el tiempo si es necesario)
        }, 100);
      } else {
        setIndexArray(memeArray.length);
        setTimeout(() => {
          display(); // Ejecutar la función display después de 100ms (puedes ajustar el tiempo si es necesario)
          $('.mouse').fadeOut(500)
        }, 100);
      }
    }
  
    if (displayNew) {
      fetchNewData();
      console.log('entro', indexArray, displayNew);
    }
  }, [displayNew]);
  

  useEffect(() => {
    const script = document.createElement('script');
    if (displayMenu) {
      // Llamar al archivo JavaScript que deseas ejecutar aquí

      script.src = '/cards.js';
      script.async = true;
      script.id = "cards"
      document.body.appendChild(script);

      gsap.fromTo("#creatingMeme .wrapper", { y: 50 }, {
        duration: 0.1,
        delay: 0.5,
        opacity: 1,
        y: 0,
        ease: "power1.inOut",
        onComplete: () => {
          animateImgs()
      }});

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [displayMenu]);


  useLayoutEffect(() => {
    console.log('Los elementos se han renderizado completamente en el DOM.');
    $('body').addClass('stop-scrolling')

        gsap.to("#photos", {
          duration: 1,
          opacity: 1,
          delay: 0.5,
          ease: "power1.inOut"
        });

        gsap.fromTo(".pushed", { opacity: 0, y: 20 } , {
          duration: 1,
          opacity: 1,
          delay: 1.5,
          ease: "power1.inOut",
          y: 0,
          stagger: 0.2,
          onComplete: () => {
            document.querySelectorAll('.ParentDiv').forEach((element) => {
              // element.classList.remove('.pushed');
              // console.log('element', element)
              $(element).removeClass('pushed')
            });

            $('body').removeClass('stop-scrolling')

            if(memeArray.length > 15){
              $('.mouse').show()
            }

          }
        });

        console.log('memeArray.length', memeArray.length, indexArray)

        if( secondEffectCompleted &&  memeArray.length > indexArray){
          console.log('displayNew', displayNew)
          resumeScroll()
        }

  }, [memeArray, secondEffectCompleted]);


  useEffect(() => {
    console.log('indexArray', indexArray)
  }, [indexArray])
  

  async function addClassShake(element){
    $(element).addClass('disabled')
    setTimeout(() => {
      $(element).removeClass('disabled')
    }, 500);
  }

  async function addClassShakeText(element){
    $(element).addClass('disabledText')
    setTimeout(() => {
      $(element).removeClass('disabledText')
    }, 500);
  }

  async function animateImgs(delay){

    gsap.fromTo(".slide__image", { scale: 1, opacity: 0 },
    {
      duration: 0.3,
      scale: 0.8,
      ease: "power1.inOut",
      delay: 0.5,
      opacity: 1,
      onComplete: () => {
          gsap.to(".slide__image", {
            duration: 0.3,
            scale: 1.2,
            onComplete: () => {
              gsap.to(".slide__image", {
                duration: 0.3,
                scale: 1,
              })
            }
          });
      }
    });
  }

  function handleInput(e){
    e.target.parentNode.dataset.replicatedValue = e.target.value;
  }

  function handleInput2(event){
    event.target.style.height = 'auto'; 
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  return (
    <div>
      <div id="add-button">
        <div id="wrapper">
          <div id="a">
            <button
              type="button"
              data-tf-popup="YV83RpIl"
              data-tf-size="100"
              className="btn btn-floating btn-lg"
              id="btn-add"
              onClick={() => createMeme()}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
          <div id="b" onClick={() => createMeme()}>
            <span>Submit your MEME</span>
          </div>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-md fixed-top d-none d-sm-block"
        style={{ padding: 0, top: 0 }}
      >
        <div className="container-fluid" style={{ padding: 0, justifyContent: "flex-start" }}>
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="collapse navbar-collapse" id="navbarCollapse"></div>
        </div>
      </nav>

      <nav
        className="navbar navbar-expand-md fixed-top d-block d-sm-none"
        style={{ padding: 0, top: 0 }}
      >
        <div className="container-fluid d-flex justify-content-center" style={{ padding: 0, justifyContent: "flex-start" }}>
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="collapse navbar-collapse" id="navbarCollapse"></div>
        </div>
      </nav>


      { firstEffectCompleted && memeArray.length == 0 &&   <p id='nothing'> ¯\_(ツ)_/¯ <br/> Nothing here at the moment.</p>}


      <div id="main_wrapper">
        <div id="smooth-content">
          <div className="loader"></div>
          <div id="photos">
            {memeArray.slice(0, indexArray).map((item) => (
              <div className='ParentDiv pushed' key={item.id}>
                <div className='item'> 
                  <div className='divHover'> 
                    <p className='name'>{item.name}</p>  
                    <p className='affiliate'>{item.affiliate}</p>  
                    <button className='btn btn-primary'>VOTE</button> 
                  </div>
                  <div className='image tip' data-tippy-arrow='false'> 
                    <img className='lazy' src={item.file} alt={item.name} />
                  </div>
                </div> 
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="preloadDiv"></div>
      <div className="mouse">
        <div className="scroll"></div>
      </div>

      <div id="scrolling"></div>

      <div id="noMessage">
        <p> Still no messages, be the first one!</p>
      </div>

      <div id='creatingMeme'>

          <main className="wrapper">

            <svg id='closeMenu' onClick={closeMenu} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>

            {/* Slides Area */}
            <section className="slides-area">
            
                <article className="slide slides-area__slide">
                <img src={img_2} alt="illustration" className="slide__image" />
        
                <div className="slide__text">
                <h2 className="slide__title">Tell us about yourself</h2>
                <p className="slide__paragraph">
                    We need you to provide us<br/>with some information
                </p>
                </div>
                <form action="jajaja" method="post">
                    <div>
                        <input autoComplete="off" className="input-field" type="text" id="name" name="name" placeholder="Name"  value={name} onChange={ (e) => setName(e.target.value)}/>
                    </div>
                    <div>
                        <input autoComplete="off" className="input-field" type="text" id="affiliate" name="affiliate" placeholder="Affilate"   value={affiliate} onChange={ (e) => setAffiliate(e.target.value)} />
                    </div>
                </form>
                
                { name != '' && affiliate != '' 
                  ? 
                  <button className="button button-next" onClick={ () =>   { window.moveToNextSlide() }} aria-label="to get next slide">Next</button>
                  : 
                  <button className="button button-next" onClick={(e) => { addClassShake($('#name')); addClassShake($('#affiliate')) }}  style={{ opacity: 0.5 }}  aria-label="to get next slide">Next</button>
                } 
                </article>

                <article className="slide slides-area__slide">

                    <h2 className="slide__paragraph title-meme">
                        Create a meme <br/> that represents: Togetherness
                    </h2>

                    <div style={{paddingBottom: '10px'}}>

                      <div id='randomIcon' aria-disabled={disabledChangeMeme} className={(disabledChangeMeme) ? 'shuffle-disabled' : ''} onClick={() => { getMemeImage() }}>
                        Change meme
                        <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-shuffle" viewBox="0 0 16 16" style={{ marginLeft: "5px"}}>
                          <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                          <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                        </svg>
                      </div>
                    </div>

                    <div id='meme-generator-container'>
                      <div id="meme-generator" className="row position-relative">
                          <img src={meme.randomImage} className="meme--image" crossOrigin="anonymous" />

                          { upload ? 
                            <>  
                              <h2 className="meme--text top">{meme.topText}</h2>
                              <h2 className="meme--text bottom">{meme.bottomText}</h2>
                            </>
                          : 
                            <>
                                {/* <input autoComplete="off" className="meme--text top" type="text" name="topText"  style={{ padding: 0, lineHeight: 0.5 }} placeholder={memePlaceholder.top}  value={meme.topText} onChange={handleChange}/> */}
                                {/* <input autoComplete="off" className="meme--text bottom" type="text" name="bottomText"  style={{ padding: 0, lineHeight: 0.5 }} placeholder={memePlaceholder.bottom}  value={meme.bottomText} onChange={handleChange} /> */}
                                <div className="grow-wrap"> 
                                  <textarea rows="1" className="top" type="text" name="topText" style={{ padding: 0 }} placeholder={memePlaceholder.top}  value={meme.topText} onChange={handleChange}  onInput={ (e) => handleInput(e) }></textarea>
                                </div>

                                <div className="grow-wrap-b"> 
                                  <textarea rows="1" className="bottom" type="text" name="bottomText" style={{ padding: 0 }} placeholder={memePlaceholder.bottom}  value={meme.bottomText} onChange={handleChange}  onKeyUp={ (e) => handleInput2(e) }></textarea>
                                </div>
                            </>
                          }
                          

                          
                      </div>
                    </div>
                    

                    <div className="slide__text">
                    {/* <h2 className="slide__title">Meme Creator</h2>  */}
                    {/* <p className="slide__paragraph">
                        Turn your words into memes!
                    </p> */}
                    </div>
                    {/* <form action="jajaja" >
                        <div>
                            <input className="input-field" autoComplete="off" type="text" id="name" name="topText" placeholder="Top text" value={meme.topText} onChange={handleChange} />
                        </div>
                        <div>
                            <input className="input-field" autoComplete="off" type="text" id="affiliate" name="bottomText" placeholder="Bottom text" value={meme.bottomText} onChange={handleChange} />
                        </div>
                    </form> */}

                    {  ( meme.topText || meme.bottomText ) != '' 
                      ?
                        <button className="button button-next" onClick={ () => {  PrintDiv() }} aria-label="to get next slide">SUBMIT</button>
                      :
                        <button className="button button-next" onClick={(e) => { addClassShakeText($('.meme--text')) }} style={{ opacity: 0.5 }} aria-label="to get next slide">SUBMIT</button>
                    } 

                </article>

                <article className="slide slides-area__slide">
                    <img src={img_3} alt="illustration" className="slide__image"/>
            
                    <div className="slide__text">
                    <h2 className="slide__title"><div className="ellipsis">Uploading your meme<span>.</span><span>.</span><span>.</span></div></h2>
                    <p className="slide__paragraph">
                      Your meme is on the runway.<br/>Hold tight! 
                    </p>
                    </div>
                </article>

                <article className="slide slides-area__slide">
                    <img src={img_4} alt="illustration" className="slide__image"/>
            
                    <div className="slide__text">
                    <h2 className="slide__title">Your meme is ready!</h2>
                    <p className="slide__paragraph">
                        Good job! Now, explore and enjoy<br/>the memes created by other people.
                    </p>
                    </div>

                    <button className="button button-next" onClick={() => { closeMenu(); $( window ).scrollTop( 0 ); } } aria-label="to get next slide">Explore</button>
                </article>
            </section>
          
              {/* Next Button  */}
          
              {/* Paginations Area  */}
              
              <section className="paginations-area"></section>
          </main>
        
      </div>


      {/* WELCOME */}

      <div id='welcome-overlay'>
        <main className="wrapper-welcome">

          <svg id='closeMenu' onClick={closeOnBoarding} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>

          {/* Slides Area */}
          <section className="">
              <article className="">
                <img src={img_1} alt="illustration" className="slide__image" />

                <div classNarme="slide__text">
                  <h2 className="slide__title stag">Working Together<br/>memes a lot!!</h2>
                  <h3 className="stag2">🎉 Let's Play the Meme Game! 🤣</h3>
                  <p className="slide__paragraph stag3" style={{ margin: '0.2rem' }} >Dive into the Meme Gallery 📸</p>
                  <p className="slide__paragraph stag3" style={{ margin: '0.2rem' }}>Spot Your Image Spark ⚡</p>
                  <p className="slide__paragraph stag3" style={{ margin: '0.2rem' }}>Add Your Magic Text, Celebrating Togetherness 🤗</p>
                </div>

                <button className="button button-next explore__gallery" aria-label="to get next slide" onClick={closeOnBoarding}>Explore the Gallery</button>
              </article>
          </section>
        </main>

      </div>

    </div>
  );
}

export default Mansonry;
