import React, { useState, useEffect } from "react"
import memesData from "../memesData.js"

import './styles/meme.css'

import html2canvas from 'html2canvas';

import { API , Storage, Amplify } from "aws-amplify";

import * as mutations from '../graphql/mutations';

import awsconfig from '../aws-exports';

Amplify.configure(awsconfig)

export default function Meme({ }) {
    
    const [meme, setMeme] = React.useState({
        topText: "",
        bottomText: "",
        randomImage: "http://i.imgflip.com/1bij.jpg" 
    })
    const [allMemes, setAllMemes] = React.useState(memesData)

    const [myMeme, setMyMeme] = React.useState('')

    const [upload, setUpload] = useState(false)

    const [name, setName] = useState('Test')
    const [description, setDescription] = useState('');
    const [readyToUpload, setReadyToUpload] = useState(false)
    
    const [userFile, setUserFile] = useState('')
    const [fileUrl, setFileUrl] = useState('');

    
    function getMemeImage() {
        const memesArray = allMemes.data.memes
        const randomNumber = Math.floor(Math.random() * memesArray.length)
        const url = memesArray[randomNumber].url
        setMeme(prevMeme => ({
            ...prevMeme,
            randomImage: url
        }))
        
    }
    
    function handleChange(event) {
        const {name, value} = event.target
        setMeme(prevMeme => ({
            ...prevMeme,
            [name]: value
        }))
    }

    function PrintDiv(){

        const div = document.getElementById('meme-generator');

        html2canvas(div, {useCORS: true, logging: true }).then(canvas => {
            var myImage = canvas.toDataURL('image/png');
            setMyMeme(canvas.toDataURL('image/png'))
            // downloadURI(myImage, "MaSimulation.png");

            const imageBuffer = Buffer.from(myImage.split(',')[1], 'base64');
            
            // console.log(imageBuffer)

            setUserFile(imageBuffer)
        });
    }

    useEffect(() => {

        if(userFile){
            console.log('userFile', userFile)
            testUpload()
        }

    }, [userFile])


    async function testUpload(){

        const fileName = `${name}_${Date.now()}.png`;

        console.log(`fileName`, fileName)

        console.log('userFile', userFile)

        const result = await Storage.put(fileName, userFile, {
            contentType: 'image/png'
        })


        await Storage.list('')
        .then((data) => {
            data.results.map( async (result) => {
                if(result.key === fileName){
                    console.log(`fileName`, fileName)
                    console.log(`key`, result.key)

                    await setFileUrl(result.key)
                    await setReadyToUpload(true)
                }
            })
        })
        .catch((err) => console.log(err));

        console.log(`result`, result)
    }


    // Use Effect to send data
    useEffect(() => {

        async function fetchData(){

            const input = {
                name: "name testing",
                affiliate: "Montevideo",
                file: `https://s3memecreator101256-dev.s3.amazonaws.com/public/${fileUrl}`,
            };

            console.log('dataUser', input)

            const newPost = await API.graphql({ query: mutations.createPostMeme, variables: { input: input } });

            console.log('newPost', newPost)
        }

        if(readyToUpload === true ){
            console.log('Fetchind data post', readyToUpload)

            setReadyToUpload(false)
            fetchData()
        }

    }, [readyToUpload])



    
    return (
        <main className="row d-flex justify-content-around w-100">

            { myMeme ?
                <>
                    <div className="">
                        <h2>PREVIEW YOUR MEME</h2>
                        <img src={myMeme} className="meme--preview"/>
                        <div className="row d-flex justify-content-center">
                            <button 
                                className="form--button mt-4 col-4 p-2"
                                onClick={() => setMyMeme()}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </>
            : 
                <>
                    <div className="form col-5 d-block">
                        <div className="row">
                            <h2 className="col-12">CREATE</h2>
                        </div>

                        <div className="row mt-2 d-flex justify-content-center">
                            <input 
                                type="text"
                                placeholder="Top text"
                                className="form--input col-8 p-2"
                                name="topText"
                                value={meme.topText}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="row mt-2 d-flex justify-content-center">
                            <input 
                                type="text"
                                placeholder="Bottom text"
                                className="form--input col-8 p-2 align-center"
                                name="bottomText"
                                value={meme.bottomText}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="row mt-2 d-flex justify-content-center">
                            <button 
                                className="form--button col-8 p-2"
                                onClick={PrintDiv}
                            >
                                Submit
                            </button>
                        </div>    
                    </div>

                    <div className="meme col-5">
                        <div className="row mt-2 d-flex justify-content-center">
                            <button 
                                className="form--button col-11 p-2"
                                onClick={getMemeImage}
                            >
                                Get a new meme image 🖼
                            </button>
                        </div>

                        <div id="meme-generator" className="row mt-4 position-relative">
                            <img src={meme.randomImage} className="meme--image" />
                            <h2 className="meme--text top">{meme.topText}</h2>
                            <h2 className="meme--text bottom">{meme.bottomText}</h2>
                        </div>

                    </div>
                </>
            }
        </main>
    )
}