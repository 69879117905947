/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePostMeme = /* GraphQL */ `
  subscription OnCreatePostMeme {
    onCreatePostMeme {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePostMeme = /* GraphQL */ `
  subscription OnUpdatePostMeme {
    onUpdatePostMeme {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePostMeme = /* GraphQL */ `
  subscription OnDeletePostMeme {
    onDeletePostMeme {
      id
      name
      affiliate
      file
      createdAt
      updatedAt
    }
  }
`;
